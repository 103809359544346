



































































import Vue from "vue";
import {
  computed,
  defineComponent,
  reactive,
  ref,
  Ref,
  watch,
} from "@vue/composition-api";
import { truncate } from "@/apps/core/modules/utils/text";

export default defineComponent({
  name: "ListFilter",
  props: {
    // filterMap: { type: Object, required: true },
    filterButtonConfigs: { type: Array, default: () => [] },
    searchActiveState: { type: Boolean, default: false },
    /* format filterButtonConfigs:
    Perlu buat class untuk config object
    filterButtonConfigs: [
      {
        field: "namaField",
        value: "",  // initial value
        valueMap: {
          "": "Label", "val1": "Label Val1", "val2": "Label Val2"
        },
        default: "val1"
      },
      { ... }
    ]
    */
  },
  setup(props, { emit }) {
    const cari: Ref<any> = ref(null);
    const isSearchActive = ref(false);
    const filterMap: Record<string, any> = reactive({ q: "" });
    const aFilterButtonConfigs: Array<Record<string, any>> = reactive([]);

    const updateFilterButtonConfigs = (configs: Array<any>) => {
      aFilterButtonConfigs.splice(0, aFilterButtonConfigs.length);
      const configArr: Array<Record<string, any>> = [];
      for (const config of configs) {
        const filterConfig = config as Record<string, any>;
        const filterRec: Record<string, any> = Object.assign({}, filterConfig);
        if (filterRec.value === undefined) {
          filterRec.value = "";
        }
        // filterRec = reactive(filterRec);
        configArr.push(filterRec);
        Vue.set(filterMap, filterConfig.field, filterRec.value);
      }
      aFilterButtonConfigs.push(...configArr);
    };

    updateFilterButtonConfigs(props.filterButtonConfigs);
    // filterMap = reactive(filterMap);

    const onFilterChanged = (filterObj: Record<string, any>, value: string) => {
      filterMap[filterObj.field] = value;
      emit("input", filterMap);
    };

    const isFilterActive = computed(() => {
      const values = Object.values(filterMap);
      return values.some((value) => !!value);
    });

    const getButtonClass = (value: string) => {
      return value ? "has-text-info-dark	has-background-info-light" : "";
      // has-text-success has-text-weight-semibold has-background-success-light
    };

    // const clearSearchKeyword = () => {
    //   filterMap.q = "";
    //   isSearchActive.value = false;
    //   emit("input", filterMap);
    // };

    const setSearchActive = async (value: boolean) => {
      isSearchActive.value = value;
      if (value) {
        await Vue.nextTick();
        const divEl: HTMLElement = cari.value.$el;
        const el = divEl.getElementsByTagName("input")[0];
        el.focus();
        el.click();
      } else {
        emit("input", filterMap);
      }
      // emit "search" event ke (list) view
      emit("search", value);
    };

    const resetFilters = () => {
      for (const field of Object.keys(filterMap)) {
        filterMap[field] = "";
      }
      for (const config of aFilterButtonConfigs) {
        config.value = "";
      }
      emit("input", filterMap);
    };

    // watch search overlay dan update value ke isSearchActive jika berubah
    watch(
      () => props.searchActiveState,
      (value) => {
        // perlu untuk menghandle cycle!!!
        if (isSearchActive.value !== value) {
          isSearchActive.value = value;
          if (!value && filterMap.q) emit("input", filterMap);
        }
      }
    );

    // watch request perubahan init filter
    watch(
      () => props.filterButtonConfigs as Array<Record<string, any>>,
      (value) => updateFilterButtonConfigs(value),
      { deep: true }
    );

    return {
      // Data
      aFilterButtonConfigs,
      filterMap,
      cari,
      isSearchActive,

      // Computed
      isFilterActive,

      // Method
      // clearSearchKeyword,
      getButtonClass,
      onFilterChanged,
      resetFilters,
      setSearchActive,
      truncate,
    };
  },
});
